
import { mapActions, mapGetters } from "vuex";
// import { i18n } from '@/i18n';
import i18n from "@/vueI18n";

import firebase from "firebase/app";
import "firebase/firestore";
import { UserModel } from "@/modules/auth/user-model";
const { fields } = UserModel;

export default {
  data() {
    return {
      prescription: null,
      title: "",
      description: "",
      comments: [],
      id: this.$route.params.id,
      rawFile: "",
      fileName: "",
      path: "",
      downloadURL: "",
      file: "",
      fileExist: false,
      progress: null,
      image: null,
      datePresentation: null,
      recordId: null,
      updatedPrescription: {},
      fileSizeExceeded: false,
      loadingUpdate: false,
      btn_disabled: false,
    };
  },
  computed: {
    lang(){
        // debugger    
        return i18n.locale
    },
    progressBar() {
      return this.progress / 100;
    },
    ...mapGetters({
      saveLoading: "prescriptions/form/saveLoading",
      record: "prescriptions/view/record",
      currentUser: "auth/currentUser",
    }),
    fields() {
      return fields;
    },
  },

  watch: {
    lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.prescriptions.editPrescription') 
        },
    async record(newvalue) {
      if (this.record) {
        this.title = this.record.title;
        this.description = this.record.description;
        this.datePresentation = this.datePresenter(newvalue.createdAt);
        if (this.record.viewers) {
          if (!this.record.viewers.hasOwnProperty(this.currentUser.id)) {
            //   debugger
            this.record.viewers[this.currentUser.id] = true;
            this.record.createdAt = new Date(
              this.record.createdAt.seconds * 1000
            );
            this.record.updatedAt = new Date(
              this.record.updatedAt.seconds * 1000
            );

            delete this.record.id;
            this.doUpdate({ id: this.recordId, values: this.record });
          }
        } else {
          this.record.viewers = {};
          this.record.viewers[this.currentUser.id] = true;
          this.record.createdAt = new Date(
            this.record.createdAt.seconds * 1000
          );
          this.record.updatedAt = new Date(
            this.record.updatedAt.seconds * 1000
          );
          delete this.record.id;
          this.doUpdate({ id: this.recordId, values: this.record });
        }
      }
    },
  },

  methods: {
    datePresenter(value) {
      return new Date(value.seconds * 1000).toString().slice(0, 16);
      // record.createdAt.seconds.toString().slice(0,16)
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    input(value) {
      this.image = value[0]["publicUrl"];
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      doUpdate: "prescriptions/form/doUpdate",
      doCreateComment: "prescriptions/form/doCreateComment",
      doFind: "prescriptions/view/doFind",
    }),
    async updatePrescription() {
      // this.loadingUpdate = true;
      this.updatedPrescription = this.record;
      this.updatedPrescription["createdAt"] = new Date(
        this.record.createdAt.seconds * 1000
      );
      this.updatedPrescription["updatedAt"] =
        firebase.firestore.Timestamp.fromDate(new Date()).toDate();
      this.updatedPrescription["title"] = this.title;
      this.updatedPrescription["description"] = this.description;
      delete this.updatedPrescription.id;

      if (this.rawFile) {
        await this.uploadFromRequest();
      } else {
        await this.doUpdate({
          id: this.recordId,
          values: this.updatedPrescription,
        });
      }
      // this.loadingUpdate = false;
    },
    openImageUpload() {
      const fileUpload = document.getElementById("file-upload");
      fileUpload.click();
    },
    async readUrl(event) {
      const profilePicture = document.getElementById("profile-pic");
      if (event.target.files && event.target.files[0]) {
        this.rawFile = event.target.files[0];
        this.fileName = event.target.files[0].name;
        var reader = new FileReader();
        reader.onload = function (e) {
          profilePicture.src = e.target.result;
          this.file = e.target.result;
        };

        reader.readAsDataURL(event.target.files[0]);
        this.fileExist = true;
        var totalBytes = this.rawFile.size;
        var _size = Math.floor(totalBytes / 1000000);
        // }
        if (_size > 1) {
          this.fileExist = false;
          this.fileSizeExceeded = true;
          this.btn_disabled = true;
        } else {
          //     await this.uploadFromRequest()
          this.fileExist = true;
          this.fileSizeExceeded = false;
          this.btn_disabled = false;
        }
      }
    },

    async uploadFromRequest() {
      this.loadingUpdate = true;
      var storage = firebase.storage().ref();
      // delete old image
      if(this.record.image){      
        var storageRef = storage.child(
          `prescription/${this.currentUser.id}/${this.recordId}/${
            Object.keys(this.record.image)[0]
          }`
        );
        // Delete the file
        storageRef
          .delete()
          .then(() => {
          })
          // .catch((error) => {
          // });
      }
      //   console.log('schema : ',this.fields.avatarsIam.fileSchema)
      var today = Date.now();
      var uploadTask = storage
        .child(`prescription/${this.currentUser.id}/${this.recordId}/${today}`)
        .put(this.rawFile);

      await uploadTask.on(
        "state_changed",
        async (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = parseFloat(progress);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        // (error) => {
        //   console.log("error", error);
        // },
        () => {
          // this.loadingUpdate = true;
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            this.downloadURL = downloadURL;
            // var httpsReference = storage.refFromURL(this.downloadURL);
            // console.log('https reference',httpsReference);
            let payload = this.updatedPrescription;
            payload.image = {};
            payload.image[today] = this.downloadURL;
            delete payload.id;
            await this.doUpdate({ id: this.recordId, values: payload });
            this.loadingUpdate = false;
          });
        }
      );
    },
  },
  mounted() {
    this.setHeaderBg("bg-shadow");
    this.setNavbar("services");
    this.setFooter(false);
  },
  async created() {
    document.title =
              this.i18n('app.title') +
              ' | ' +
              this.i18n('drugDeal.prescriptions.editPrescription')   


    this.recordId = this.$route.params.id;
    await this.doFind({ id: this.$route.params.id });
  },
};
